// @flow

import React from 'react';
import styled from 'styled-components';
import { ConfettiFramedContainer } from '@latitude/confetti-framed-container';
import { Box, Flex } from '@latitude/box';
import {
  ALIGN,
  ALIGN_ITEMS,
  PADDING,
  COLOR,
  FLEX_WRAP,
  GUTTER,
  MARGIN
} from '@latitude/core/utils/constants';
import { parseContent } from '../../utils/helpers';

type LoanProcessItem = {
  title: string,
  description: string
};

type Props = {
  /** id */
  id: string,
  /** List title. */
  title?: string,
  /** List item content. */
  data: LoanProcessItem[],
  /** background colour */
  bgColor?: string
};

const List = styled.ol`
  list-style-type: none;
  margin: -5px 0 0;
  padding-left: 0;
`;

const ListItem = styled.li`
  display: flex;
  counter-increment: list-num;
  min-height: 70px;
  font-weight: 300;
  padding: 16px;
  line-height: 22px;
  color: ${COLOR.BODY};
  border-bottom: 1px solid #dae3e7;
  text-align: left;

  :nth-last-child(1) {
    border-bottom: none;
  }

  :before {
    content: counter(list-num);
    display: block;
    position: relative;
    left: -16px;
    height: 30px;
    width: 30px;
    min-width: 30px;
    background: ${({ bgColor }: Props) => bgColor};
    border-radius: 100%;
    font-size: 14px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    color: ${COLOR.WHITE};
  }
`;

const ContentContainer = styled(Box)`
ul {
  margin: 0 0 0 -${MARGIN.M24};
  li {
    line-height: 26px;
    position: relative;
    list-style: none;
    margin: 0 0 0 ${MARGIN.M8};
    ::before {
      font-size: 36px;
      position: absolute;
      top: 0;
      left: -${MARGIN.M24};
      color: ${COLOR.BLUE_LIGHT};
      content: '•';
    }
  }
`;

const TitleText = styled.span`
  font-weight: bold;
`;

const LoanProcessList = (props: Props) => {
  const { title, data, bgColor = COLOR.BLUE, id } = props;

  return (
    <ConfettiFramedContainer minHeight="auto" heading={title} id={id}>
      <Box padding={`${PADDING.P24}`}>
        <List>
          {data.map((item: LoanProcessItem, i: number) => (
            <ListItem key={i} bgColor={bgColor}>
              <Flex
                className="row w-100"
                flexWrap={FLEX_WRAP.WRAP}
                alignItems={ALIGN_ITEMS.FLEX_START}
                marginLeft={`-${GUTTER.G15}`}
                marginRight={`-${GUTTER.G15}`}
              >
                <Flex
                  className="col-md-4 col-sm-12 col-xs-12"
                  paddingLeft={GUTTER.G15}
                  paddingRight={GUTTER.G15}
                  align={ALIGN.CENTER}
                >
                  <TitleText>{item.title}</TitleText>
                </Flex>
                <Flex
                  className="col-md-8 col-sm-12 col-xs-12"
                  paddingLeft={GUTTER.G15}
                  paddingRight={GUTTER.G15}
                  align={ALIGN.CENTER}
                >
                  <ContentContainer>
                    {parseContent(item.description)}
                  </ContentContainer>
                </Flex>
              </Flex>
            </ListItem>
          ))}
        </List>
      </Box>
    </ConfettiFramedContainer>
  );
};

export default LoanProcessList;
